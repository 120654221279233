<template>
  <v-container fluid>
    <div class="d-flex">
    <div class="flex-grow-1">
      <h3>이미지 리스트 보여주기</h3>
      <div class="list-box mt-3 pa-3 white">
        <h2 class="tit-h2 d-flex">
        상담이미지목록
        <div class="ml-auto">
          보기정렬
          <button type="button" class="btn-default v-btn v-btn--outlined theme--light v-size--small"
                  @click="changeRow(3, 6)">
            <span class="v-btn__content">3열</span>
          </button>
          <button type="button" class="btn-default ml-2 v-btn v-btn--outlined theme--light v-size--small"
                  @click="changeRow(4, 8)">
            <span class="v-btn__content">4열</span>
          </button>
          <button type="button" class="btn-default ml-2 v-btn v-btn--outlined theme--light v-size--small"
                  @click="changeRow(5, 10)">
            <span class="v-btn__content">5열</span>
          </button>
          <button type="button" class="btn-default ml-2 v-btn v-btn--outlined theme--light v-size--small"
                  @click="changeRow(6, 18)">
            <span class="v-btn__content">6열</span>
          </button>
          <button type="button" class="btn-default ml-2 v-btn v-btn--outlined theme--light v-size--small"
                  @click="changeRow(7, 21)">
            <span class="v-btn__content">7열</span>
          </button>
        </div>
      </h2>
      <template>
        <table style="display: grid;">
          <div class="image_list">

            <v-card
                class="mx-auto"
            >
              <v-container fluid v-if="historyList.length > 0">
                <v-row
                       v-for=" i in rows"
                       :key="i">
                  <v-col
                      v-for="j in cols"
                      :key="j">
                    <v-card v-if="i == 1 && historyList[j-1] != undefined">
                      <v-card-title v-text="historyList[j-1].BRD_TIT"></v-card-title>
                      <v-img
                          :style="{'background': getFileSrc(1,cols,j), 'background-size': 'cover'}"
                          class="white--text align-end"
                          height="200px"
                      >
                      </v-img>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-card-title v-text="historyList[j-1].FST_BRD_DT_D"></v-card-title>
                      </v-card-actions>
                    </v-card>
                    <v-card v-if="i == 2 && historyList[cols + j-1] != undefined">
                      <v-card-title v-text="historyList[cols + j-1].BRD_TIT"></v-card-title>
                      <v-img
                          :style="{'background': getFileSrc(2,cols,j), 'background-size': 'cover'}"
                          class="white--text align-end"
                          height="200px"
                      >
                      </v-img>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-card-title v-text="historyList[cols + j-1].FST_BRD_DT_D"></v-card-title>
                      </v-card-actions>
                    </v-card>
                    <v-card v-if="i == 3 && historyList[cols + cols + j-1] != undefined ">
                      <v-card-title v-text="historyList[cols + cols + j-1].BRD_TIT"></v-card-title>
                      <v-img
                          :style="{'background': getFileSrc(3,cols,j), 'background-size': 'cover'}"
                          class="white--text align-end"
                          height="200px"
                      >
                      </v-img>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-card-title v-text="historyList[cols + cols + j-1].FST_BRD_DT_D"></v-card-title>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </div>
        </table>
      </template>
      </div>
    </div>
    </div>
  </v-container>
</template>

<script>
import api from "@/store/apiUtil.js";

export default {
  name: "ImageList",
  components: {
  },
  props: {},
  data() {
    return {

      ASP_CUST_KEY: 'HKCTALKMNG',
      BRD_TIT  : '', // 검색할 제목
      BRD_ID:'4',
      PAGES_CNT: 1, // header 현재 보여지는 페이지 기본 1
      ROW_CNT  : 1000, // 한 페이지에 보여줄 게시글의 수
      pageCount: 1,

      TOT_COUNT: '', // 총 게시글 수

      // as is list data
      FST_BRD_DT  : '',
      PATH_TYPE   : '',
      FILE_KEY    : '',
      FILE_NM     : '',
      FST_BRD_DT_D: '',

      //페이징
      page       : 1,
      pageSize   : 10,
      listCount  : 0,
      historyList: [],
      rows       : 2,

      // css 열 바꿈
      articles: [],
      cols    : 5,
    };
  },
  computed: {
    pages() {
      let _this = this;
      if (_this.pageSize == null || _this.listCount == null) return 0;
      return Math.ceil(_this.listCount / _this.pageSize);
    },
  },
  watch: {},
  created() {
    this.getImageList();
  },
  mounted() {
    this.BRD_ID = this.getBrdId();
  },
  methods: {

    getBrdId() {
      let menuList = {};
      menuList = this.$store.getters["userStore/GE_USER_MENU"];
      for(let i in menuList) {
        if(menuList[i].C_NODE_NO === "M810607") {
          let tempUrl = menuList[i].NODE_URL;
          let idIndex = tempUrl.indexOf("BRD_ID=");
          console.log("bordID ::::::::::::::" + tempUrl.substring(idIndex+7));
          return tempUrl.substring(idIndex+7);
        }
      }
    },

    async getImageList() { // 조회 버튼 클릭

      this.historyList = [];
      api
        .post(
          "api/chat/setting/image-manage/list", //api url입력
          {
            ASP_NEWCUST_KEY: this.ASP_CUST_KEY,
            BRD_ID: this.BRD_ID,
            START_DT: '20220414',
            END_DT: '20220418',
            BRD_TIT: this.BRD_TIT,
            PAGES_CNT: '1',
            ROW_CNT: '1000',
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY: this.ASP_CUST_KEY,
          },
          {
            head: {
              SERVICE: "chat.setting.image-manage",
              METHOD: "list",
              TYPE: "BIZ_SERVICE",
              ROW_CNT: "200",
              PAGES_CNT: "1",
            },
          }
        )
        .then((response) => {
          console.log(response.data.DATA);
          this.setImageList(response.data);
        })
        .catch((err) => {
          alert(err);
        });
      },

    setImageList(response) {
      let header = response.HEADER;
      let data = response.DATA;
      this.gridListDataText = response.DATA;

      this.articles = Object.assign([], data);
      console.log("1111111111111"+ JSON.stringify(this.gridListDataText));
      console.log("data"+ JSON.stringify(data));
      console.log("this.articles"+ JSON.stringify(this.articles));

      //페이징
      this.initPage(this.pageSize);

    },

    // 3, 4, 5, 6, 7열 클릭 시 보여주는 게시글 조절
    changeRow(cols, pageSize) {
      //histroyList =  한 페이지에 들어가는 articles
      this.historyList = this.articles.slice(0, pageSize);
      this.cols = cols; //3
      this.pageSize = pageSize;

      //row 구하기
      let totalLength = this.historyList.length; //6
      if(totalLength % cols > 0){
        this.rows = Math.ceil(totalLength/cols);
      } else{
        this.rows = totalLength/cols;
      }

      //페이징
      this.initPage();

    },

    initPage() {
      let _this = this;
      _this.page = 1;
      _this.listCount = _this.articles.length;
      if (_this.listCount < _this.pageSize) {
        _this.historyList = _this.articles;
      } else {
        _this.historyList = _this.articles.slice(0, _this.pageSize);
      }
    },

    updatePage(pageIndex) {
      let _this = this;
      let _start = (pageIndex - 1) * _this.pageSize;
      let _end = pageIndex * _this.pageSize;
      _this.historyList = _this.articles.slice(_start, _end);
      _this.page = pageIndex;
    },

    getFileSrc(num,cols,j){
      var strImageUrl = "";
      if (num == 1){
        strImageUrl = `url(/upload/${this.historyList[j-1].FILE_PATH}/${this.historyList[j-1].SAVE_FILENAME})`;
      } else if (num == 2) {
        strImageUrl = `url(/upload/${this.historyList[cols + j-1].FILE_PATH}/${this.historyList[cols + j-1].SAVE_FILENAME})`;
      } else if (num == 3) {
        strImageUrl = `url(/upload/${this.historyList[cols + cols + j-1].FILE_PATH}/${this.historyList[cols + cols + j-1].SAVE_FILENAME})`;
      } 
      return strImageUrl;
    }
  },
};
</script>

<style scoped></style>
