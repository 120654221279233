<template>
  <v-card class="pa-3" color="cyan mt-3" dark outlined>
    <v-card-title> 타임라인 </v-card-title>
    <div class="d-flex">
      <!-- <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown"> -->
      <div class="flex-glow-1">
        <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
          <v-timeline-item
            v-for="(item, i) in items"
            :key="i"
            :color="item.color"
            :icon="item.icon"
            fill-dot
          >
            <v-card :color="item.color" dark>
              <v-card-title class="text-h6"> Lorem Ipsum Dolor </v-card-title>
              <v-card-text class="white text--primary">
                <p>
                  Lorem ipsum dolor sit amet, no nam oblique veritus. Commune
                  scaevola imperdiet nec ut, sed euismod convenire principes at.
                  Est et nobis iisque percipit, an vim zril disputando
                  voluptatibus, vix an salutandi sententiae.
                </p>
                <v-btn :color="item.color" class="mx-0" outlined>
                  Button
                </v-btn>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </div>

      <div>
        <v-timeline dense clipped>
          <v-timeline-item
            v-for="(item, i) in items"
            :key="i"
            :color="item.color"
            :icon="item.icon"
            fill-dot
          >
            <draggable class="list-group" :list="items" group="people">
              <p>{{ item.date }}</p>
              <transition-group type="transition" name="flip-list" tag="ul">
                <li :key="item">{{ item.name }}</li>
                <!-- <v-card :color="item.color" dark class="hand">
                <v-card-title class="text-h6"> Lorem Ipsum Dolor </v-card-title>
              </v-card> -->
              </transition-group>
            </draggable>
          </v-timeline-item>
        </v-timeline>
      </div>
    </div>
  </v-card>
</template>

<script>
import draggable from "vuedraggable";
export default {
  name: "TimeLine",
  components: {
    draggable,
  },
  props: {},
  data() {
    return {
      items: [
        {
          color: "red lighten-2",
          icon: "mdi-star",
          name: "서울",
          date: "2022-01-01",
          key: 1,
        },
        {
          color: "purple darken-1",
          icon: "mdi-book-variant",
          name: "경기",
          date: "2022-01-01",
          key: 2,
        },
        {
          color: "green lighten-1",
          icon: "mdi-airballoon",
          name: "인천",
          date: "2022-01-02",
          key: 3,
        },
        {
          color: "indigo",
          icon: "mdi-adjust",
          name: "대전",
          date: "2022-01-03",
          key: 4,
        },
      ],
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
  activated() {},
  deactivated() {},
  destroyed() {},
};
</script>

<style scoped>
.list-group ul {
  padding: 0px;
}

.list-group ul li {
  list-style-type: none;
  background: blue;
  padding: 5px;
}

.list-box {
  flex-basis: 50%;
}

.disabled {
  opacity: 0.6;
}

.ghost {
  opacity: 0.5;
  box-shadow: 0 0 0 3px orange inset;
  background: red !important;
}

.hand {
  cursor: pointer;
}

.hand.disabled {
  cursor: not-allowed;
}

.list-enter-active,
.list-leave-active {
  transition: all 1s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
</style>
