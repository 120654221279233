<template>
  <v-container fluid style="width: 100%">
    <!-- <figure class="highcharts-figure"> -->
    <!-- <div id="sparkline" style="background: red"> -->
    <!-- </figure> -->

    <!-- <vue-highcharts
      class="line-chart"
      :options="options"
      ref="lineCharts"
    ></vue-highcharts>
    <button @click="load">load</button> -->
    <!-- </div> -->
    <h3 class="mt-5">[HighChart] LineChart</h3>
    <LineChart class="mt-2"></LineChart>

    <h3 class="mt-5">[HighChart] PieChart</h3>
    <PieChart class="mt-2"></PieChart>

    <h3 class="mt-5">[HighChart] BarChart</h3>
    <BarChart class="mt-2"></BarChart>

    <h3 class="mt-5">[Chartjs] LineChart</h3>
    <chartjsLineChart
      class="mt-2"
      :height="400"
      :styles="{ background: '#fff' }"
    ></chartjsLineChart>

    <h3 class="mt-5">[Chartjs] BarChart</h3>
    <chartjsBarChart
      class="mt-2"
      :height="300"
      :styles="{ background: '#fff' }"
    ></chartjsBarChart>

    <h3 class="mt-5">[Chartjs] DoughnutChart</h3>
    <chartjsDoughnutChart
      class="mt-2"
      :height="300"
      :styles="{ background: '#fff' }"
    ></chartjsDoughnutChart>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
// import VueHighcharts from "./HighChart.vue";
// // import VueHighcharts from "vue2-highcharts";
// import Highcharts from "highcharts";
// import Exporting from "highcharts/modules/exporting";
// Exporting(Highcharts);

import LineChart from "./LineChart";
import PieChart from "./PieChart";
import BarChart from "./BarChart";
import chartjsLineChart from "./chartjsLineChart";
import chartjsBarChart from "./chartjsBarChart";
import chartjsDoughnutChart from "./chartjsDoughnutChart";

export default {
  name: "MENU_Chart",
  props: {},
  components: {
    LineChart,
    PieChart,
    BarChart,
    chartjsLineChart,
    chartjsBarChart,
    chartjsDoughnutChart,
  },
  data() {
    return {};
  },
  computed: {
    // ...mapGetters({
    //   progress_bar: "commonStore/GE_COMMON_PROGRESS_BAR",
    // }),
  },
  watch: {},
  created() {},
  mounted() {
    // this.load1();
  },
  methods: {
    // load() {
    //   let lineCharts = this.$refs.lineCharts;
    //   lineCharts.delegateMethod("showLoading", "Loading...");
    //   setTimeout(() => {
    //     lineCharts.addSeries(this.asyncData);
    //     lineCharts.hideLoading();
    //   }, 2000);
    // },
    // load1() {
    //   console.log("load1 !!!!!!!!!!!!!!!!!!!!");
    //   let lineCharts = this.$refs.lineCharts;
    //   setTimeout(() => {
    //     lineCharts.chart.reflow();
    //   }, 1000);
    // },
    // load() {
    //   let lineCharts = this.$refs.lineCharts;
    //   //charts.showLoading('loading');
    //   // you also can use the delegateMethod()
    //   lineCharts.delegateMethod("showLoading", "Loading...");
    //   setTimeout(() => {
    //     lineCharts.addSeries(asyncData);
    //     lineCharts.hideLoading();
    //   }, 200);
    // },
  },
  activated() {},
  deactivated() {},
  destroyed() {},
};
</script>

<style>
/* .highcharts-figure,
.highcharts-data-table table {
  min-width: 360px;
  max-width: 800px;
  margin: 1em auto;
} */
.line-chart {
  min-width: 100%;
  background: blue;
  margin: 0;
}
/* .highcharts-data-table table {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}

.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}

.highcharts-data-table tr:hover {
  background: #f1f7ff;
} */
</style>
