<template>
  <v-container fluid>
    <!-- <div id="aaaaaa" name="aaaaaa">
      <ckeditor
        :editor="editor"
        v-model="editorData"
        :config="editorConfig"
        tag-name="textarea"
      ></ckeditor>
    </div> -->

    <ckeditor v-model="editorData" :config="editorConfig"></ckeditor>
    <v-textarea
      outlined
      name="input-7-4"
      label="HTML Tag"
      v-model="editorData"
      class="mt-4"
      background-color="grey lighten-3"
      readonly
    ></v-textarea>
  </v-container>
</template>

<script>
export default {
  name: "MenuCkeditor",
  props: {
    height: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      //   editor: ClassicEditor,
      //   editorData: "",
      //   editorConfig: {
      //     toolbar: {
      //       items: [
      //         "heading",
      //         "|",
      //         "alignment", // <--- ADDED
      //         "bold",
      //         "italic",
      //         "link",
      //         "bulletedList",
      //         "numberedList",
      //         "uploadImage",
      //         "blockQuote",
      //         "undo",
      //         "redo",
      //       ],
      //     },
      //     language: "ko",
      //   },
      editorData: "",
      editorConfig: {
        // toolbar: [
        //   ["Source"],
        //   ["Styles", "Format", "Font", "FontSize"],
        //   ["Bold", "Italic"],
        //   ["Undo", "Redo"],
        //   ["About"],
        // ],
        removeButtons: "About",
        // height: "100%",
        // height: "500px",
      },
    };
  },
  computed: {
    setHeight() {
      let value = "100%";
      if (this.height !== null) {
        value = this.height + "px";
      }
      return value;
    },
  },
  watch: {},
  created() {},
  mounted() {
    this.editorConfig.height = this.setHeight;
  },
  methods: {},
  activated() {},
  deactivated() {},
  destroyed() {},
};
</script>
