<template>
  <div class="layout-subcontainer">
    <v-container fluid>
      <v-card class="pa-3" color="light-blue lighten-4" outlined>
        <v-card-title class="indigo--text font-weight-black">
          에디터(ckeditor4)
        </v-card-title>
        <v-card-text>
          <v-row class="black--text">
            <Ckeditor></Ckeditor>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="pa-3 mt-3" color="light-blue lighten-4" outlined>
        <v-card-title class="indigo--text font-weight-black">
          드래그 앤 드롭
        </v-card-title>
        <v-card-text>
          <v-row class="black--text">
            <DragAndDrop></DragAndDrop>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="pa-3 mt-3" color="light-blue lighten-4" outlined>
        <v-card-title class="indigo--text font-weight-black">
          엑셀 업로드/다운로드
        </v-card-title>
        <v-card-text>
          <v-row class="black--text">
            <Excel></Excel>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="pa-3 mt-3" color="light-blue lighten-4" outlined>
        <v-card-title class="indigo--text font-weight-black">
          프로그래스바
        </v-card-title>
        <v-card-text>
          <v-row class="black--text">
            <ProgressBar></ProgressBar>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="pa-3 mt-3" color="light-blue lighten-4" outlined>
        <v-card-title class="indigo--text font-weight-black">
          오디오 플레이
        </v-card-title>
        <v-card-text>
          <v-row class="black--text">
            <Audio></Audio>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="pa-3 mt-3" color="light-blue lighten-4" outlined>
        <v-card-title class="indigo--text font-weight-black">
          공통코드
        </v-card-title>
        <v-card-text>
          <v-row class="black--text">
            <CommonCode></CommonCode>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="pa-3 mt-3" color="light-blue lighten-4" outlined>
        <v-card-title class="indigo--text font-weight-black">
          파일 업로드
        </v-card-title>
        <v-card-text>
          <v-row class="black--text">
            <FileUpload></FileUpload>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="pa-3 mt-3" color="light-blue lighten-4" outlined>
        <v-card-title class="indigo--text font-weight-black">
          차트(HighChart)
        </v-card-title>
        <v-card-text>
          <v-row class="black--text">
            <Chart></Chart>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="pa-3 mt-3" color="light-blue lighten-4" outlined>
        <v-card-title class="indigo--text font-weight-black">
          이미지 리스트
        </v-card-title>
        <v-card-text>
          <v-row class="black--text">
            <ImageList></ImageList>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- <TimeLine></TimeLine> -->
    </v-container>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import Ckeditor from "@/components/Ckeditor";
import DragAndDrop from "@/components/DragAndDrop";
import TimeLine from "@/components/TimeLine";
import Excel from "@/components/Excel";
import ProgressBar from "@/components/ProgressBar";
import Audio from "@/components/Audio";
import CommonCode from "@/components/CommonCode";
import FileUpload from "@/components/FileUpload";
import Chart from "@/components/Chart";
import ImageList from "@/components/ImageList";
// import Toast from "@/views/help/ToastDefault";

export default {
  name: "MenuSample",
  components: {
    Ckeditor,
    DragAndDrop,
    TimeLine,
    Excel,
    ProgressBar,
    Audio,
    CommonCode,
    FileUpload,
    Chart,
    ImageList,
    // Toast
  },
  data() {
    return {
      userNameTwo: "",
      files: "",
      data1: [
        {
          name: "1",
          key: 1,
        },
        {
          name: "2",
          key: 2,
        },
        {
          name: "3",
          key: 3,
        },
        {
          name: "4",
          key: 4,
        },
        {
          name: "5",
          key: 5,
        },
      ],
      data2: [
        {
          name: "a",
          key: 6,
        },
        {
          name: "b",
          key: 7,
        },
        {
          name: "c",
          key: 8,
        },
        {
          name: "d",
          key: 9,
        },
        {
          name: "e",
          key: 10,
        },
      ],
    };
  },
  computed: {},
  watch: {},
  created() {
    console.log("[sample0][created] start~!!");
  },
  methods: {},
  activated() {
    console.log("[sample0][activated] start~!!");
  },
  deactivated() {
    console.log("[sample0][deactivated] start~!!");
  },
  destroyed() {
    console.log("[sample0][destroyed] start~!!");
  },
};
</script>

<style scoped>
.feedback {
  background-color: rgb(255, 220, 220);
  border: 2px dashed black;
}
.p20 {
  padding: 20px;
}

.normal {
  background-color: grey;
}

.dragArea {
  min-height: 10px;
}

.sortable-chosen {
  opacity: 0.7;
  background-color: #dcdcdc;
}

.sortable-ghost {
  background-color: #dcdcdc;
}
.handle {
  padding: 5px;
  margin-right: 10px;
  border: solid #000 1px;
  cursor: move;
}
</style>
